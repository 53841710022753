import { ChangeDetectorRef, Component, ElementRef, Injectable, NgZone, OnInit, ViewChild } from '@angular/core';
import { QuillEditorComponent } from 'ngx-quill'
import { MatDialogRef } from '@angular/material/dialog';
import { AddNewLeadComponent } from '../k-board/add-new-lead/add-new-lead.component';

import { FormControl, FormGroup } from '@angular/forms';
import { SettingService } from 'src/app/settings/setting.service';
import { Constants } from 'src/app/Constants';
import { KanbanService } from '../kanban.service';
import { ToastrService } from 'ngx-toastr';

import { ErrorToastComponent } from 'src/app/shared-files/error-toast/error-toast.component';
import { SuccessToastComponent } from 'src/app/shared-files/success-toast/success-toast.component';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatChipInputEvent, MatSnackBar } from '@angular/material';
import { InfoToastComponent } from 'src/app/shared-files/info-toast/info-toast.component';
import { Observable } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { map, startWith } from 'rxjs/operators';
import { LabelConstants } from 'src/app/LabelConstants';
import { tap } from 'rxjs/operators';
@Component({
  selector: 'app-new-meeting',
  templateUrl: './new-meeting.component.html',
  styleUrls: ['./new-meeting.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class NewMeetingComponent implements OnInit {

  public myDateControl = new FormControl(new Date());
  @ViewChild(QuillEditorComponent) editor: QuillEditorComponent
  isProgressVisible = false;
  file: any;
  content = '';
  addMomContent = '';

  finalStartDateTime: Date; // Holds the final start date and time
  startDateValue: string;  // Variable to store the selected start date
  startTimeValue: string; // Variable to store the start time

  finalEndDateTime: Date; // Holds the final end date and time
  endDateValue: string;  // Variable to store the selected end date
  endTimeValue: string; // variable to store the end time

  nextActionDate: Date; // Holds the next action date
  meetingTitle: string;

  selectedPlantsForMeeting: any[] = [];

  opportunityList: any;
  selectedOpportunity: any[] = [];

  contactList: any;
  selectedContact: any[] = [];
  meetingCategoryOptions: any[] = ["New Sales Opportunity", "Courtesy Meeting", "New Product Development", "Complaint", "Feedback"];
  selectedMeetingCategory: string;
  productOptions : any[] = ["Billet", "Wire Rod", "PFA", "T-Ingot", "Ingot", "RP", "Others"];
  selectedProduct : any [] = [];
  variantOptions : any[] = ["Normal", "Restora", "Ultra-Restora"];
  selectedVariant : any[] = [];
  remarks : any = '';
  meetingTypeOptions: any[] = ["In-Person"];
  selectedMeetingType: string;

  searchControlPlants = '';
  searchControlAccounts = '';
  plantOptionsSelected: any[] = [];
  accountOptionsSelected: any[] = [];
  searchControl = '';
  plantOptions: any[] = [];
  selectedMeeting: any;
  plantOptionsAllIds: any[] = [];
  plants: any;
  accounts: any[] = [];
  boardIdForSobOpportunity = Constants.boardIdForSobOpportunity;
  boardIdForSobPlant = Constants.boardIdForSobPlant;
  boardIdForSobContacts = Constants.boardIdForSobContacts;
  times = [
    "00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30",
    "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30",
    "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30",
    "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30",
    "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30",
    "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30"
  ];

  timesEnd: any[] = [];

  minDate: Date;
  minEndDate: Date;
  maxDate: Date;
  mentionList: any = [];
  mommodules = {
    mention: {
      mentionListClass: 'ql-mention-list mat-elevation-z8',
      mentionContainerClass: 'ql-mention-list-container',
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ["@"],
      onSelect: (item, insertItem) => {
        const editor = this.editor.quillEditor
        insertItem(item)
        editor.insertText(editor.getLength() - 1, '', 'user')
        this.mentionList.push(item.id);
      },
      source: async (searchTerm, renderList) => {

        const matchedPeople = await this.suggestPeople(searchTerm);
        console.log('matchedPeople', matchedPeople)
        renderList(matchedPeople, searchTerm);
      }
    },
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: (range, context) => {
            console.log('enter', context);
            return true;
          },
        },
      },
    },
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      // ['blockquote', 'code-block'],

      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      // [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
      // [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
      // [{ 'direction': 'rtl' }],                         // text direction

      // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'font': [] }],
      [{ 'align': [] }],

      ['clean'],                                         // remove formatting button

      // ['link', 'image', 'video']                         // link and image, video
    ]
  }
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      // ['blockquote', 'code-block'],

      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      // [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
      // [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
      // [{ 'direction': 'rtl' }],                         // text direction

      // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'font': [] }],
      [{ 'align': [] }],

      ['clean'],                                         // remove formatting button

      // ['link', 'image', 'video']                         // link and image, video
    ]
  }
  isPlatformDropdownVisible: boolean = false;
  sobUserId: any;
  huntingUserId: any;
  selectedPlatform: string;
  selectedBusinessId: string;
  accountConfig = {
    "key": "laneTask",
    "field": "account-name",
    "rawValue": null,
    "type": "belongsTo",
    "value": "",
    "optional": true,
    "display": true,
    "displayWhileListing": true,
    "showTotal": false,
    "displayName": "Account Name",
    "dataList": [],
    "values": null,
    "additionalData": null,
    "operationsData": null,
    "operations": null,
    "index": 6,
    "icon": "",
    "relationKey": Constants.boardIdForCRMAccount,
    "nameField": false
  };
  customSelectWithMultipleAccounts: any[] = [];
  accountQuery: any;
  selectedDeals: any[] = [];
  pageSize = 20;
  pageFrom = 0;
  dealsList: any;
  form = new FormGroup({
    time: new FormControl()
  });
  accountIdsList: any;
  accountOptions: any;
  selectedAccounts: any;
  isCreatingPreviousMeeting: boolean = false;

  minDateForNextAction: Date = new Date();
  isLoading: boolean = false;
  sendMomMail: boolean = false; // Set initial state (false for disabled)
  srmUserId: string;
  platformOptions = [];
  supplierAllDetails: any = [];
  supplierAllDetailsFilter: any = [];
  selectedSupplier: any = [];
  searchControlSupplier = '';

  contactCtrl = new FormControl();
  filteredcontacts: Observable<string[]>;
  contacts: string[] = [];
  allcontacts: string[] = [];
  sobBusinessId: string = Constants.businessIdForSOB;
  crmBusinessId: string = Constants.businessIdForCRM;
  srmBusinessID: string = Constants.businessIdForSRM;
  @ViewChild('contactInput') contactInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  allUserList: any;
  allUserListSearch: any;
  selectedAttendees: any;
  selectedMomAttendees: any;
  attendeeLabel: any = LabelConstants.attendeeLabel;
  searchControlAttendees: any = '';

  sobAccountBoardId: string = Constants.boardIdForVedantaAccount;
  sobPlantBoardId: string = Constants.boardIdForSobPlant;
  sobContactBoardId: string = Constants.boardIdForSobContacts;

  userName: string;
  userEmail: string;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  chipCtrl = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  boo = false;
  private speechRecognition: any;
  timerInterval: any;
  startTime: number;
  isRecognizing: boolean = false; // Add this property
  @ViewChild('timer') timerElement: ElementRef;
  selectedLanguage: string = 'en-US'; // Default language
  languages = [
    { code: 'en-US', name: 'English (US)' },
    { code: 'en-GB', name: 'English (UK)' },
    { code: 'es-ES', name: 'Spanish (Spain)' },
    { code: 'fr-FR', name: 'French (France)' },
    { code: 'de-DE', name: 'German (Germany)' },
    // Add more languages as needed
  ];

  constructor(private _snackBar: MatSnackBar, public dialogRef: MatDialogRef<AddNewLeadComponent>, public settingService: SettingService, public _kService: KanbanService, public toasterService: ToastrService, public cRef: ChangeDetectorRef, private _ngZone: NgZone) {
    // Set minDate to two days back from the current date
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() - 2);

    this.minEndDate = new Date();
    this.minEndDate.setDate(this.minEndDate.getDate() - 2);

    // Set maxDate to two days upcoming from the current date
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 2);

    this.filteredcontacts = this.contactCtrl.valueChanges.pipe(
      startWith(null),
      map((contact: string | null) => contact ? this._filter(contact) : this.allcontacts.slice()));

  }

  ngOnInit() {
    this.sobUserId = localStorage.getItem('sobUserId');
    this.huntingUserId = localStorage.getItem('crmUserId');
    this.srmUserId = localStorage.getItem('srmUserId');
    this.selectedBusinessId = localStorage.getItem('selectedBusinessId');

    const defaultStartDate = new Date();
    this.startDateValue = defaultStartDate.toISOString();

    this.loadAccounts();
    this.loadUsers(this.selectedBusinessId);
    this.mentionList = [];
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allcontacts.filter(contact => contact.toLowerCase().indexOf(filterValue) === 0);
  }

  loadAccounts() {
    this.getLaneTaskAccountList();
  }
  getLaneTaskAccountList() {
    this.isLoading = true;
    this.settingService.fetchAccountsByUserId(this.huntingUserId).subscribe(res => {
      this.isLoading = false;
      this.accounts = res['result'];
      this.accountOptionsSelected = this.accounts;
      this.accountOptionsSelected.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
      this.accountOptionsSelected = this.accountOptionsSelected.slice(0, 100);

    },
      (error) => {
        console.log('Error while getting accounts', error);
      }
    );
  }

  getQuery(boardId: any) {
    return {
      where: {
        boardId: {
          type: 'search',
          operator: 'or',
          value: [
            {
              field: 'boardId.raw',
              value: boardId,
            },
          ],
        },
      },
      sort: {
        field: 'updatedAt',
        order: 'desc',
      },
      from: 0,
      size: 20,
    };
  }

  getPlantsQuery(boardId: any, accountIds: any) {
    const accountQueries = accountIds.map(value => {
      return {
        field: 'data.account.raw',
        value: value
      };
    });

    return {
      where: {
        boardId: {
          type: 'search',
          operator: 'or',
          value: [
            {
              field: 'boardId.raw',
              value: boardId,
            },
          ],
        },
      },
      nestedWhere: {
        account_query: {
          type: 'search',
          operator: 'or',
          value: accountQueries
        }
      },
      sort: {
        field: 'updatedAt',
        order: 'desc',
      },
      from: 0,
      size: 20,
    };
  }

  getContactsQuery(boardId: any, accountIds: any) {
    const accountQueries = accountIds.map(value => {
      return {
        field: 'data.account.raw',
        value: value
      };
    });

    return {
      where: {
        boardId: {
          type: 'search',
          operator: 'or',
          value: [
            {
              field: 'boardId.raw',
              value: boardId,
            },
          ],
        },
      },
      nestedWhere: {
        account_query: {
          type: 'search',
          operator: 'or',
          value: accountQueries
        }
      },
      sort: {
        field: 'updatedAt',
        order: 'desc',
      },
      from: 0,
      size: 20,
    };
  }

  loadPlants() {
    this.isLoading = true;
    const query = this.getPlantsQuery(this.sobPlantBoardId, this.customSelectWithMultipleAccounts);
    this._kService.getLaneTask(query).subscribe(response => {
      this.isLoading = false;
      this.plants = response['result'];
      this.plantOptions = response['result'];
      this.plantOptions.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
      this.plantOptionsSelected = this.plantOptions;
      this.plantOptionsSelected.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
      this.plantOptionsAllIds = this.plantOptionsSelected.map(element => element.id);
      this.plantOptionsSelected = this.plantOptionsSelected.slice(0, 100);
    });
  }

  loadSupplier() {
    console.log("onLoadSupplier");
    this.isLoading = true;
    this.settingService.fetchRecordsByBoardId(Constants.boardIdForSrmSupplier).subscribe(response => {
      this.isLoading = false;
      this.supplierAllDetails = response['result'];
      this.supplierAllDetailsFilter = this.supplierAllDetails;
      this.supplierAllDetailsFilter.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
      this.supplierAllDetailsFilter = this.supplierAllDetailsFilter.slice(0, 100);
    });
  }

  onSupplierSelectionChange(selection: any) {
    console.log('onSupplierSelectionChange', selection);
    this.selectedSupplier = selection;
  }

  filterOptionsSupplier(supplierName) {
    this.supplierAllDetailsFilter = this.supplierAllDetails.filter(option => (option.name).toLowerCase().includes(supplierName.toLowerCase()));
  }

  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      if ((value || '').trim()) {
        this.contacts.push(value.trim());
      }
      if (input) {
        input.value = '';
      }
      this.contactCtrl.setValue(null);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.contacts.push(event.option.viewValue);
    this.contactInput.nativeElement.value = '';
    this.contactCtrl.setValue(null);
  }

  remove(contact: string): void {
    const index = this.contacts.indexOf(contact);

    if (index >= 0) {
      this.contacts.splice(index, 1);
    }
  }

  ngAfterViewInit() {
    this.cRef.detectChanges();
  }

  fileUpload(event) {
    this.isProgressVisible = true;
    if (event[0]) {
      this.file = event[0];
    }
    else {
      this.file = event.target.files[0];
    }

  }

  closeDialog() {
    this.dialogRef.close()
  }

  openPlantSelect() {
    console.log('openPlantSelect:::')
  }

  filterOptionsPlants(event) {
    // this.plantOptionsSelected = this.plants.map(a => ({ ...a }));
    const filterValue = event.target.value.toLowerCase();
    this.plantOptionsSelected = this.plants.filter(option => (option.name).toLowerCase().includes(filterValue));
    this.plantOptionsSelected.slice(0, 100);
  }

  clearPlants() {
    this.searchControlPlants = '';
    this.plantOptions = this.plants.map(a => ({ ...a }));
  }

  clearOpportunity() {
    console.log('clearOpportunity');
  }

  clearContacts() {
    console.log('clearContacts');
    this.selectedContact = null;
  }

  onApplyContacts() {
    console.log('on apply Contacts selected for adhoc meeting', this.selectedContact);
  }

  onApplyPlants() {
    this.searchControlPlants = "";
    console.log('on apply plant selected for meeting', this.selectedPlantsForMeeting);
    // this.getOpportunityDetailsBasedOnPlants();
  }

  getOpportunityDetailsBasedOnPlants() {
    console.log('getOpportunityDetailsBasedOnPlants');
    let plantsIdListForQuery = this.selectedPlantsForMeeting.map(element => element.id);
    const valueArray = this.generateValueArray(plantsIdListForQuery, 'data.plantname.raw');
    const valueArrayForContact = this.generateValueArray(plantsIdListForQuery, 'data.plant');

    let obj = {
      "where": {
        "boardId": {
          "type": "search",
          "operator": "or",
          "value": [
            {
              "field": "boardId.raw",
              "value": this.boardIdForSobOpportunity
            }
          ]
        }
      },
      nestedWhere: {
        plant_query: {
          type: 'search',
          operator: 'or',
          value: valueArray
        }
      },
      "sort": { "field": "updatedAt", "order": "desc" },

      "from": 0,
      "size": 20,
      "userId": this.sobUserId
    }
    this.isLoading = true;
    this._kService.getLaneTaskByUserId(obj).subscribe(task => {
      this.isLoading = false;
      if (task['result'] != null && task['result'].length > 0) {
        this.opportunityList = task['result'];
        console.log("opportunityListResult:: ", this.opportunityList);
      } else {
        // this.toasterService.error("No Opportunities for selected plants");
      }
    });
    this.fetchContacts();
  }

  generateValueArray(plantsIdList, fieldName) {
    return plantsIdList.map(element => ({
      field: fieldName,
      value: element
    }));
  }

  async fetchContacts() {
    const query = this.getContactsQuery(this.sobContactBoardId, this.customSelectWithMultipleAccounts);
    this.isLoading = true;
    this._kService.getLaneTask(query).subscribe(task => {
      this.isLoading = false;
      if (task['result'] != null && task['result'].length > 0) {
        this.contactList = task['result'];
      }
      else {
        setTimeout(() => {
          this._snackBar.openFromComponent(InfoToastComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['atom-toast', 'information'],
            duration: 6000,
            data: "No Contacts Mapped With Selected Account."
          });
        }, 1500);
      }
    });
  }

  onApplyOpportunity() {
    console.log('on apply opportunity selected for adhoc meeting', this.selectedOpportunity);
  }

  selectDate(date: string) {
    this.endDateValue = null;
    this.endTimeValue = null;
    this.startTimeValue = null;

    this.startDateValue = date;
    let sDate = new Date(this.startDateValue);
    this.minDateForNextAction=sDate;
    if (sDate > this.minEndDate) {
      this.minEndDate = sDate;
    }
    else {
      this.minEndDate = new Date();
      this.minEndDate.setDate(this.minEndDate.getDate() - 2);
    }
  }

  selectTime(selectedTime: string) {
    this.startTimeValue = selectedTime;
    const [hours, minutes] = this.startTimeValue.split(":").map(Number);

    this.finalStartDateTime = new Date(this.startDateValue);
    this.finalStartDateTime.setHours(hours, minutes, 0, 0);
    this.timesEnd = this.times; // for displaying Available End Times

    const selectedIndex = this.times.findIndex(time => time === selectedTime);
    let startDate = new Date(this.startDateValue);

    if (selectedIndex !== this.times.length - 1) {
      this.endDateValue = this.startDateValue;
      this.timesEnd = this.timesEnd.slice(selectedIndex + 1);
      this.minEndDate = startDate;
    }
    else {
      if (startDate > this.minEndDate) {
        startDate.setDate(startDate.getDate() + 1);
        this.minEndDate = startDate;
      }

      this.timesEnd = this.times; // for displaying Available End Times
      const endDateValue = new Date(this.startDateValue);
      endDateValue.setDate(endDateValue.getDate() + 1);

      this.endDateValue = endDateValue.toISOString(); // Convert back to string
      this.endTimeValue = null;
    }
  }

  selectEndDate(date: string) {
    this.endTimeValue = null;
    this.endDateValue = date;
    const endDateValue = new Date(this.endDateValue);
    const startDateTimeValue = new Date(this.finalStartDateTime);
    if (startDateTimeValue < endDateValue) {
      this.timesEnd = this.times;
    }
  }

  selectEndTime(selectedTime: string) {

    this.endTimeValue = selectedTime;

    const [hours, minutes] = this.endTimeValue.split(":").map(Number);
    const endDate = new Date(this.endDateValue);
    this.finalEndDateTime = endDate;
    this.finalEndDateTime.setHours(hours, minutes, 0, 0);

    const currentTime: Date = new Date();
    if (currentTime < this.finalStartDateTime) {
      this.isCreatingPreviousMeeting = false;
    } else if (currentTime > this.finalStartDateTime) {
      this.isCreatingPreviousMeeting = true;
    } else {
      this.isCreatingPreviousMeeting = true;
    }
    console.log("finalStart", this.finalStartDateTime);
    console.log("finalEnd", this.finalEndDateTime);
  }

  combineDateAndTime(startDateValue: any, timeToAdd: any) {
    const startDate = new Date(startDateValue);
    const [hours, minutes, meridiem] = timeToAdd.match(/(\d+):(\d+) (\w{2})/).slice(1);

    let parsedHours = parseInt(hours, 10);
    if (meridiem.toLowerCase() === 'pm' && parsedHours !== 12) {
      parsedHours += 12;
    } else if (meridiem.toLowerCase() === 'am' && parsedHours === 12) {
      parsedHours = 0;
    }
    startDate.setHours(parsedHours, parseInt(minutes, 10), 0, 0);

    return startDate;
  }

  isDisabledOption(optionValue: string) {
    console.log("optionValue", optionValue)
  }

  postSubmit(momType: any) {
    let isValidResp = this.validateRequest();
    if (!isValidResp || !isValidResp['isValid']) {
      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 6000,
        data: isValidResp.message
      });
      return;
    }
    const originalStartTimeZone = this.getTimeZone(this.finalStartDateTime);
    const originalEndTimeZone = this.getTimeZone(this.finalEndDateTime);

    let tmp = this.content;
    if (this.content) {
      tmp = tmp.replace(/(<([^>]+)>)/ig, '');
    }
    if (this.content && (this.content.trim() != "" && tmp.trim() != "")) {
      const body = {
        contentType: 'html',
        content: this.content,
      };
      const start = {
        dateTime: this.finalStartDateTime,
        timeZone: originalStartTimeZone
      };
      const end = {
        dateTime: this.finalEndDateTime,
        timeZone: originalEndTimeZone
      };
      let attendeesList;
        attendeesList = this.selectedContact.map(contact => {
          const searchData = contact.searchData;
          return {
            type: 'required',
            emailAddress: {
              name: searchData.name,
              address: searchData.email,
            },
          };
        });
      let attendees;
      if (this.selectedAttendees) {
        attendees = this.selectedAttendees.map(attendee => attendee.id);
      }
      let obj = {
        'originalStartTimeZone': originalStartTimeZone,
        'originalEndTimeZone': originalEndTimeZone,
        'subject': this.meetingTitle,
        "collaborators": attendees,
        'body': body,
        'start': start,
        'end': end,
        'attendees': attendeesList,
        'meetingType': this.selectedMeetingType,
        'selectedPlatform': this.selectedPlatform,
        'source': 'WEB',
        'product': this.selectedProduct,
        'variant': this.selectedVariant,
        'remarks': this.remarks,
        'meetingCategory': this.selectedMeetingCategory,
        'accountList': this.customSelectWithMultipleAccounts,
        'plantIdList': this.selectedPlantsForMeeting.map(element => element.id)
      };
      this.settingService.createTeamMeetingByUserId(obj, this.sobUserId).subscribe((res) => {
        if (res) {
          const status = res['status'];
          if (status === 200) {
            this._snackBar.openFromComponent(SuccessToastComponent, {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              panelClass: ['atom-toast', 'success'],
              duration: 6000,
              data: "New Meeting Created Successfully"
            });
            console.log("New Meeting Created Successfully, isCreatingPreviousMeeting", this.isCreatingPreviousMeeting)
            if (this.isCreatingPreviousMeeting) {
              const id = res['result'].id;
              if (this.addMomContent && (this.addMomContent.trim() != "")) {
                const momObj = this.createMOMObject(id, momType);

                this.addMOMAndUploadFile(momObj);
              }
            }
            else {
              this.closeDialog();
            }
          }
          else {
            this._snackBar.openFromComponent(ErrorToastComponent, {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              panelClass: ['atom-toast', 'error'],
              duration: 6000,
              data: "Failed to create new meeting"
            });
          }
        }
      });
    }
  }

  sendMail(event) {
    this.sendMomMail = event;
  }

  createMOMObject(id: string,momType: any): any {
    let attendees;
    if (this.selectedMomAttendees) {
      attendees = this.selectedMomAttendees.map(attendee => ({"id":attendee.id}));
    }
    let obj = {
      "meeting": {"id":id},
      "attendees": attendees,
      "message": this.addMomContent,
      "sendMomMail": this.sendMomMail,
      "nextActionDate": this.nextActionDate,
      'source': 'WEB',
      "momType": momType,
      "mention":this.mentionList
    };
    return obj;
  }

  addMOMAndUploadFile(obj: any): void {
    let reqFunc = this._kService.addMOM(obj);
    reqFunc.subscribe(res => {
      this.sendMomMail = false;
      if (res['status'] == 200) {
        this._snackBar.openFromComponent(SuccessToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'success'],
          duration: 6000,
          data: "MOM Added Successfully"
        });
        // this.toasterService.success("MOM Added Successfully");

        if (this.file) {
          const uploadData = new FormData();
          uploadData.append('file', this.file, this.file.name);
          this._kService.uploadSubDocuments(uploadData, res['result'].id, ("momId")).subscribe(file => {
            if (file['status'] == 200) {
            }
            this.clearFormAndCloseDialog();
            this._snackBar.openFromComponent(SuccessToastComponent, {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              panelClass: ['atom-toast', 'success'],
              duration: 6000,
              data: "Attachment uploaded Successfully"
            });
            // this.toasterService.success("Attachment uploaded Successfully");
          });
        } else {
          this.clearFormAndCloseDialog();
        }
      }
    });
  }

  clearFormAndCloseDialog(): void {
    this.content = null;
    this.addMomContent = '';
    this.startDateValue = null;
    this.startTimeValue = null;
    this.endDateValue = null;
    this.endTimeValue = null;
    this.selectedPlantsForMeeting = null;
    this.selectedOpportunity = null;
    this.meetingTitle = null;
    this.selectedContact = null;
    this.nextActionDate = null;
    this.finalStartDateTime = null;
    this.finalEndDateTime = null;
    this.addMomContent = null;
    this.file = null;
    this.mentionList=[];
    this.dialogRef.close();
  }

  validateRequest() {
    let errorMessage = null;
    if (this.customSelectWithMultipleAccounts == null || this.customSelectWithMultipleAccounts.length < 1) {
      errorMessage = 'Account :  Required';
      return {
        "message": errorMessage,
        "isValid": false
      };
    }

    if (this.meetingTitle == null || this.meetingTitle.trim() == '') {
      errorMessage = 'Meeting Title :  Required';
      return {
        "message": errorMessage,
        "isValid": false
      };
    }
    if (this.selectedMeetingType == null || this.selectedMeetingType.trim() === '') {
      errorMessage = 'Meeting Type :  Required';
      return {
        "message": errorMessage,
        "isValid": false
      };
    }
    if (this.selectedMeetingCategory == null || this.selectedMeetingCategory.trim() === '') {
      errorMessage = 'Meeting Category :  Required';
      return {
        "message": errorMessage,
        "isValid": false
      };
    }
    else if(this.selectedMeetingCategory.includes('New Product Development')){
      if (this.selectedProduct == null || this.selectedProduct.length === 0) {
        errorMessage = 'Product :  Required';
        return {
          "message": errorMessage,
          "isValid": false
        };
      }
      if (this.selectedVariant == null || this.selectedVariant.length === 0) {
        errorMessage = 'Varient :  Required';
        return {
          "message": errorMessage,
          "isValid": false
        };
      }
    }
    if (this.finalStartDateTime == null || this.finalStartDateTime == undefined) {
      errorMessage = 'Start Datetime :  Required';
      return {
        "message": errorMessage,
        "isValid": false
      };
    }
    if (this.finalEndDateTime == null || this.finalEndDateTime == undefined) {
      errorMessage = 'End Datetime :  Required';
      return {
        "message": errorMessage,
        "isValid": false
      };
    }
    if (this.content == null || this.content.trim() == '') {
      errorMessage = 'Meeting body : Required';
      return {
        "message": errorMessage,
        "isValid": false
      };
    }
    if (this.isCreatingPreviousMeeting) {
      if (this.addMomContent == null || this.addMomContent.trim() == '') {
        errorMessage = 'MOM body : Required';
        return {
          "message": errorMessage,
          "isValid": false
        };
      }
      // if (this.nextActionDate == null || this.nextActionDate == undefined) {
      //   errorMessage = 'Next Action Date :  Required';
      //   return {
      //     "message": errorMessage,
      //     "isValid": false
      //   };
      // }
      else {
        return {
          "message": "Success",
          "isValid": true
        }
      }
    } else {
      return {
        "message": "Success",
        "isValid": true
      };
    }

  }

  getTimeZone(date: Date) {
    const dateToTimeZone = new Date(date);
    const timeZone = new Intl.DateTimeFormat("en", { timeZoneName: "long" }).formatToParts(dateToTimeZone).pop().value;
    return timeZone;
  }

  onSelectAccountGetDeals(ids: string[]): void {
    if (!ids || ids.length === 0) {
      this.accountQuery = null;
    } else {
      const accountQueries = ids.map(value => {
        return {
          field: 'data.accountname.raw',
          value: value
        };
      });
      let obj = {
        where: {
          boardId: {
            type: "search",
            operator: "or",
            value: [
              {
                field: "boardId.raw",
                value: this.sobAccountBoardId
              }
            ]
          }
        },
        sort: { field: "updatedAt", order: "desc" },
        from: this.pageFrom,
        size: this.pageSize
      };

      let query = {
        query: {
          nestedWhere: {
            account_query: {
              type: 'search',
              operator: 'or',
              value: accountQueries
            }
          }
        }
      };

      this.accountQuery = query;
      if (this.accountQuery) {
        console.log("Account query", this.accountQuery)
        if (!obj['nestedWhere']) {
          obj['nestedWhere'] = {};
        }
        obj['nestedWhere']["account_query"] = this.accountQuery['query']['nestedWhere']["account_query"];
      }
      this.getDealsUsingAccount(obj);
    }
  }

  getDealsUsingAccount(obj: any) {
    this._kService.getLaneTaskByUserId(obj).subscribe((response) => {
      const result = response['result'];
      if (result && result.length > 0) {
        this.dealsList = result;
      } else {
        this.dealsList = [];
        this._snackBar.openFromComponent(InfoToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'information'],
          duration: 6000,
          data: "No Deals Mapped With Selected Account" + "Info"
        });

        // this.toasterService.info("No Deals Mapped With Selected Account", "Info");
      }
      console.log("deals response", this.dealsList);
    });

  }

  onApplyDeals() {
    console.log("onApplyDeals", this.selectedDeals);
  }

  clearDeals() {
  }

  filterOptionsAccounts(event) {
    const filterValue = event.target.value.toLowerCase();
    this.accountOptionsSelected = this.accounts.filter(option => (option.name).toLowerCase().includes(filterValue));
  }

  onApplyAccounts() {
    this.searchControlAccounts = "";
    this.handleApplySelections();
  }

  handleApplySelections() {
    console.log("handleApplySelections", this.selectedAccounts);
    this.customSelectWithMultipleAccounts = this.selectedAccounts.map(item => item.id);
    this.loadPlants();
    this.fetchContacts();
  }

  getPlants(customSelectWithMultipleAccounts: any[]) {
    this.loadPlants();
  }

  clearAccounts() {
    this.searchControlAccounts = '';
    this.accountOptionsSelected = this.accounts.map(a => ({ ...a }));
    this.dealsList = [];
    this.selectedPlantsForMeeting=[];
    this.selectedContact=[];
    this.plantOptionsSelected=[];
    this.contactList=[];
  }

  recognize() {
    this.getTranscript({ locale: this.selectedLanguage }).subscribe(transcript => {
      console.log("transcript", transcript);
      if (this.addMomContent == null) {
        this.addMomContent = '';
      }
      if (transcript !== '' && this.boo) {
        this.addMomContent += ' ' + transcript;
      }
    });
  }

  getTranscript({ locale = 'en-US' }: { locale?: string } = {}): Observable<string> {
    return new Observable(observer => {
      const SpeechRecognition = window['webkitSpeechRecognition'] || window['SpeechRecognition'];
      this.speechRecognition = new SpeechRecognition();
      this.speechRecognition.continuous = true;
      this.speechRecognition.interimResults = true;
      this.speechRecognition.lang = locale;

      this.speechRecognition.onresult = (event) => {
        let interimTranscript = '';
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          const transcript = event.results[i][0].transcript.trim();
          if (event.results[i].isFinal) {
            this.boo = true;
            this._ngZone.run(() => observer.next(transcript));
          } else {
            this.boo = false;
            interimTranscript += transcript;
            this._ngZone.run(() => observer.next(interimTranscript));
          }
        }
      };

      this.speechRecognition.start();

      return () => {
        this.speechRecognition.stop();
        observer.complete();
      };
    });
  }

  startRecognition() {
    this.isRecognizing = true;
    this.recognize();
    this.startTimer();
  }

  stopRecognition() {
    this.isRecognizing = false;
    if (this.speechRecognition) {
      this.speechRecognition.stop();
    }
    this.stopTimer();
  }

  startTimer() {
    this.startTime = Date.now();
    console.log("startTimer", this.startTime);
    this.timerInterval = setInterval(() => {
      const elapsedTime = Date.now() - this.startTime;
      const minutes = Math.floor(elapsedTime / 60000);
      const seconds = Math.floor((elapsedTime % 60000) / 1000);
      this.timerElement.nativeElement.innerText = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }, 1000);
  }

  stopTimer() {
    clearInterval(this.timerInterval);
    if (this.timerElement) {
      this.timerElement.nativeElement.innerText = '00:00';
    }
  }

  loadUsers(businessId: string) {
    const obj = {
      from: 0,
      size: 100,
      businessId: businessId
    };

    this.settingService.getUserDetailsForOwnerDropDown(obj).subscribe(list => {

      const userList = list['result'].map(user => {
        const teamList = user.userTeams ? user.userTeams.map(team => team.id) : [];
        return { ...user, userTeams: teamList };
      });
      userList.sort((a, b) => a.name.localeCompare(b.name));
      this.allUserList = userList;
      this.allUserListSearch = this.allUserList;
    });
  }

  onAttendeeSelectionChange(selection: any) {
    this.selectedAttendees = selection;
  }
  onMomAttendeeSelectionChange(selection: any) {
    this.selectedMomAttendees = selection;
  }

  filterOptionsAttendees(event) {
    this.allUserListSearch = this.allUserList.filter(option => (option.name).toLowerCase().includes(event.toLowerCase()));
  }

  rmeoveSelectedFile() {
    this.file = null;
  }
  async suggestPeople(searchTerm) {
    return new Promise((resolve, reject) => {
      let allPeople = [];
      let obj = {
        searchKey: searchTerm,
        businessId: localStorage.getItem('selectedBusinessId')
      };
      this.settingService.fetchUserListAutosuggest(obj).subscribe(
        (response) => {
          if (response) {
            let alluser: any = response;
            for (let i = 0; i < alluser.length; i++) {
              let user = { id: alluser[i]["id"], value: alluser[i]["name"] };
              allPeople.push(user);
            }
            console.log('allPeople', allPeople);
            resolve(allPeople);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

}
