import { Component, Injectable, NgZone, OnInit,ViewChild } from "@angular/core";
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS, MatDialog, MatSnackBar, MatDatepicker } from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { ToastrService } from "ngx-toastr";
import { Constants } from "src/app/Constants";
import { PagesService } from "src/app/pages/pages.service";
import { SettingService } from "src/app/settings/setting.service";
import { KanbanService } from "../kanban.service";
import { tap } from 'rxjs/operators';
import { LabelConstants } from "src/app/LabelConstants";
import { FormControl } from "@angular/forms";
import moment, { Moment } from "moment";
import { NewMeetingComponent } from "../new-meeting/new-meeting.component";
import { SuccessToastComponent } from "src/app/shared-files/success-toast/success-toast.component";
import { ErrorToastComponent } from "src/app/shared-files/error-toast/error-toast.component";
import { QuillEditorComponent } from 'ngx-quill'
import { InfoToastComponent } from "src/app/shared-files/info-toast/info-toast.component";
import { saveAs } from 'file-saver';
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
    monthDayLabel: 'MMM YYYY', // Adding a new format for month and day only
  },
};

@Component({
  selector: 'app-sop-management',
  templateUrl: './sop-management.component.html',
  styleUrls: ['./sop-management.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
})

@Injectable({
  providedIn: 'root'
})
export class SopManagementComponent implements OnInit {
@ViewChild(QuillEditorComponent) editor: QuillEditorComponent
  sobUserId: any;
  huntingUserId: any;
  srmUserId: string;

  selectedBusinessId: string;
  switchBusiness: any;

  minDate: Date = new Date();
  minEndDate: Date = new Date();
  maxDate: Date = new Date();
  currentTime: Date = new Date();

  sobBusinessId: string = Constants.businessIdForSOB;
  crmBusinessId: string = Constants.businessIdForCRM;
  srmBusinessID: string = Constants.businessIdForSRM;

  huntersLabel: string = LabelConstants.huntersLabel;
  KAMLabel: string = LabelConstants.KAMLabel;
  SRMLabel: string = LabelConstants.SRMLabel;
  vedantaLabel: string= LabelConstants.VedantaLabel;
  attendeeLabel: string = this.vedantaLabel;

  isLoading: boolean = false;

  accountDetails: any[] = [];
  accountDetailsTrimmed: any[] = [];

  plantDetails: any[] = [];
  plantDetailsTrimmed: any[] = [];
  selectedPlantsForMeeting: any[] = [];
  searchControlPlants = '';

  datewisemap = new Map<any, any>();
  meetingDetail: any = [];
  meetingContacts: any = [];

  isRefresh = false;

  selectedMeeting: any;

  file: any = null;
  content = '';

  momAttendees: any = [];

  allUserList: any = [];
  allUserListSearch: any = [];
  allTaskOwnerUserListSearch: any = [];
  allTaskAssigneeUserListSearch: any = [];
  allTaskCollaboratorUserListSearch: any = [];

  taskDetails: any = {
    id:null,
    addMomDisplayText: "ADD MOM & Task",
    fetchedTaskTitle: "",
    fetchedTaskDescription: "",
    fetchedTaskCreationDate: "",
    fetchedTaskDueDate: "",
    fetchedTaskPlantsValue: "",
    fetchedTaskAccountsValue: "",
    fetchedTaskOwnerValue: "",
    fetchedTaskAssigneeValue: "",
    fetchedTaskCollaboratorValue: ""
  };
  
  momDetails: any = {
    id:null,
    momType:null,
    addMomDisplayText: "ADD MOM",
    fetchedMOMContent: "",
    fetchedMOMCreationDate: "",
    fetchedMOMNextActionDate: "",
    fetchedMOMValue: "",
    fetchedMOMAttachment: []
  };
  draftMomDetails: any = {
    id:null,
    momType:null,
    addMomDisplayText: "ADD MOM",
    fetchedMOMContent: "",
    fetchedMOMCreationDate: "",
    fetchedMOMNextActionDate: "",
    fetchedMOMValue: "",
    fetchedMOMAttachment: [],
    fetchedAttendees:[]
  };
  date = new FormControl(moment());

  selectedTab: string = 'ALL';
  startDateSelected: any = null;
  endDateSelected: any = null;

  selectedAccountsForMOM: any[] = [];
  accountOptionsSelected: any[] = [];
  searchControlAccounts = '';

  nextActionDate: any = new Date();
  taskDueDate: any = new Date();
  selectedAttendees: any = [];
  selectedTaskOwners: any = [];
  selectedTaskAssignees: any = [];
  selectedTaskCollaborators: any = [];
  taskTitle: string;
  taskDescription:string;
  isHidden = true;

  sendMomMail: boolean = false; // Set initial state (false for disabled)
  sobAccountBoardId: string = '66c8384e8535f255a8b12efd';
  sobPlantBoardId: string = '6494a60df92a8255c71e39b5';
  sobContactBoardId: string = Constants.boardIdForSobContacts;
  enableAddMomButton: boolean = false;
  times = [
    "00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30",
    "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30",
    "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30",
    "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30",
    "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30",
    "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30"
  ];
  timesEnd: any[] = [];
  mentionList: any = [];
  mentionListForNewMeeting: any = [];
  mommodules = {
    mention: {
      mentionListClass: 'ql-mention-list mat-elevation-z8',
      mentionContainerClass: 'ql-mention-list-container',
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ["@"],
      onSelect: (item, insertItem) => {
        const editor = this.editor.quillEditor
        insertItem(item)
        editor.insertText(editor.getLength() - 1, '', 'user')
        this.mentionList.push(item.id);
      },
      source: async (searchTerm, renderList) => {
        const matchedPeople = await this.suggestPeople(searchTerm);
        console.log('matchedPeople', matchedPeople)
        renderList(matchedPeople, searchTerm);
      }
    },
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: (range, context) => {
            console.log('enter', context);
            return true;
          },
        },
      },
    },
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      // ['blockquote', 'code-block'],
      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      // [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
      // [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
      // [{ 'direction': 'rtl' }],                         // text direction
      // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['clean'],                                         // remove formatting button
      // ['link', 'image', 'video']                         // link and image, video
    ]
  }
  mobileMOMModules = {
    mention: {
      mentionListClass: 'ql-mention-list mat-elevation-z8',
      mentionContainerClass: 'ql-mention-list-container',
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ["@"],
      onSelect: (item, insertItem) => {
        const editor = this.editor.quillEditor
        insertItem(item)
        editor.insertText(editor.getLength() - 1, '', 'user')
        this.mentionList.push(item.id);
      },
      source: async (searchTerm, renderList) => {
        const matchedPeople = await this.suggestPeople(searchTerm);
        console.log('matchedPeople', matchedPeople)
        renderList(matchedPeople, searchTerm);
      }
    },
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: (range, context) => {
            console.log('enter', context);
            return true;
          },
        },
      },
    },
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      // ['blockquote', 'code-block'],
      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      // [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
      // [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
      // [{ 'direction': 'rtl' }],                         // text direction
      // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['clean'],                                         // remove formatting button
      // ['link', 'image', 'video']                         // link and image, video
    ]
  }
  mobileNewMeetingModules = {
    mention: {
      mentionListClass: 'ql-mention-list mat-elevation-z8',
      mentionContainerClass: 'ql-mention-list-container',
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ["@"],
      onSelect: (item, insertItem) => {
        const editor = this.editor.quillEditor
        insertItem(item)
        editor.insertText(editor.getLength() - 1, '', 'user')
        this.mentionListForNewMeeting.push(item.id);
      },
      source: async (searchTerm, renderList) => {
        const matchedPeople = await this.suggestPeople(searchTerm);
        console.log('matchedPeople', matchedPeople)
        renderList(matchedPeople, searchTerm);
      }
    },
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: (range, context) => {
            console.log('enter', context);
            return true;
          },
        },
      },
    },
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      // ['blockquote', 'code-block'],
      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      // [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
      // [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
      // [{ 'direction': 'rtl' }],                         // text direction
      // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['clean'],                                         // remove formatting button
      // ['link', 'image', 'video']                         // link and image, video
    ]
  }
  // new meeting from PWA
  allUserListSearchNewMeeting:any=[];
  newMeetingSelectedAttendees: any = [];
  searchControlAttendees: any = '';
  selectedAccounts:any;
  customSelectWithMultipleAccounts: any[] = [];
  plants: any;
  plantOptions: any[] = [];
  plantOptionsSelected: any[] = [];
  plantOptionsAllIds: any[] = [];
  accounts: any[] = [];
  contactList: any;
  selectedContact: any[] = [];
  meetingCategoryOptions: any[] = ["New Sales Opportunity", "Courtesy Meeting", "New Product Development", "Complaint", "Feedback"];
  selectedMeetingCategory: string;
  productOptions : any[] = ["Billet", "Wire Rod", "PFA", "T-Ingot", "Ingot", "RP", "Others"];
  selectedProduct : any [] = [];
  variantOptions : any[] = ["Normal", "Restora", "Ultra-Restora"];
  selectedVariant : any[] = [];
  remarks : any = '';
  meetingTypeOptions: any[] = ["In-Person"];
  showAdHocMeetingPopup: boolean = false;
  contentForNewMeeting = '';
  meetingTitle: string;
  selectedMeetingType: string;
  nextActionDateForNewMeeting: any = new Date();
  finalStartDateTime: Date; // Holds the final start date and time
  startDateValue: string;  // Variable to store the selected start date
  startTimeValue: string; // Variable to store the start time
  finalEndDateTime: Date; // Holds the final end date and time
  endDateValue: string;  // Variable to store the selected end date
  endTimeValue: string; // variable to store the end time
  isCreatingPreviousMeeting: boolean = false;
  showAddMOMPopup: boolean = false;
  showAddTASKPopup: boolean = false;
  selectedPlatform: string;
  showMeetingDetailsPopup: boolean = false;
  constructor(private _snackBar: MatSnackBar, public pagesService: PagesService, public dialog: MatDialog, public settingService: SettingService, public _kService: KanbanService, public toasterService: ToastrService, private _ngZone: NgZone) {
    this.minDate;
    this.minDate.setDate(this.minDate.getDate() - 2);
    this.minEndDate = new Date();
    this.minEndDate.setDate(this.minEndDate.getDate() - 2);
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 2);
  }

  ngOnInit() {
    this.mentionList = [];
    this.sobUserId = localStorage.getItem('sobUserId');
    this.huntingUserId = localStorage.getItem('crmUserId');
    this.srmUserId = localStorage.getItem('srmUserId');
    this.selectedBusinessId = localStorage.getItem('selectedBusinessId');
    this.switchBusiness = this.settingService._ManagementData.subscribe(res => {
      this.handleBusinessSelection(res);
    });
    this.loadMeetings({});
  }
  handleBusinessSelection(res) {
    const selectedBusiness = localStorage.getItem('selected_business');
    if (res && res.length > 0) {
      this.updateSelectedBusiness(res, selectedBusiness);
    } else {
      this.clearBusinessSelection();
    }
    this.setBusinessDetails(res);
  }
  setBusinessDetails(res) {
    let businessId, businessName;
    switch (res) {
      case 'SOB Management':
        businessId = this.sobBusinessId;
        businessName = 'SOB Management';
        break;
      case 'CRM Management':
        businessId = this.crmBusinessId;
        businessName = 'CRM Management';
        break;
      case 'SRM Management':
        businessId = this.srmBusinessID;
        businessName = 'SRM Management';
        break;
      default:
        return;
    }

    this.selectedBusinessId = businessId;
    localStorage.setItem('selectedBusinessId', businessId);
    localStorage.setItem('selected_business', businessName);
  }
  updateSelectedBusiness(res, selectedBusiness) {
    if (selectedBusiness != null && selectedBusiness != res) {
      this.clearLaneSelection();
    } else if (selectedBusiness == null) {
      this.clearLaneSelection();
    }
    localStorage.setItem('selected_business', res);
  }
  clearBusinessSelection() {
    this.clearLaneSelection();
    localStorage.removeItem('selected_business');
  }
  clearLaneSelection() {
    localStorage.removeItem('lane_board');
    localStorage.removeItem('selected_lane');
  }
  getQuery(boardId: any) {
    return {
      where: {
        boardId: {
          type: 'search',
          operator: 'or',
          value: [
            {
              field: 'boardId.raw',
              value: boardId,
            },
          ],
        },
      },
      sort: {
        field: 'updatedAt',
        order: 'desc',
      },
      from: 0,
      size: 20,
    };
  }
  tabClick(tab: any) {
    this.meetingTabSelection(tab.tab.textLabel);
  }
  meetingTabSelection(selectedTab: string) {
    let obj = {};
    if (this.startDateSelected !== null && this.endDateSelected !== null) {
      obj = {
        "startDate": this.startDateSelected,
        "endDate": this.endDateSelected,
      };
    }
    this.selectedTab = selectedTab;
    if (this.selectedTab === "MOM") {
      this.loadMOMMeetings(obj);
    }
    else {
      this.loadMeetings(obj);
    }
  }
  loadMeetings(obj) {
    this.isLoading = true;
    this.settingService.fetchMeetings(obj).subscribe(response => {
      this.isLoading = false;
      if (response["result"]) {
        this.datewisemap = new Map(Object.entries(response["result"]));
        this.meetingDetail = this.datewisemap.size > 0 ? this.datewisemap : null;
        this.meetingContacts = this.datewisemap.size > 0 ? this.datewisemap : [];
        this.isRefresh = true;

        const reversedEntries = Array.from(this.datewisemap.entries()).reverse();

        let isNavigationClicked = false;
        reversedEntries.forEach(([key, value]: [any, any]) => {
          if (!isNavigationClicked) {
            const keyDate = new Date(key);
            if (keyDate.getDate() > this.currentTime.getDate() - 1) {
              for (let index = 0; index < value.length; index++) {
                const element = value[index];
                const meetingStartTime = new Date(element.start.dateTime);
                meetingStartTime.setHours(meetingStartTime.getHours() + 5); // Subtract 5 hours
                meetingStartTime.setMinutes(meetingStartTime.getMinutes() + 30); // Subtract 30 minutes
                if (meetingStartTime > this.currentTime) {
                  this.onClickNavigation(value[index]);
                  isNavigationClicked = true;
                  break;
                }
                else if (keyDate.getDate() > this.currentTime.getDate()) {
                  this.onClickNavigation(value[index]);
                  isNavigationClicked = true;
                  break;
                }
              }
            }
          }
        });
      }
      else {
        this.datewisemap = null;
        this.meetingDetail = null;
        this.meetingContacts = null;
      }
    });
  }
  onClickNavigation(selectedSection: any) {
    this.isRefresh = false;
    selectedSection.isSelected = true;
    this.datewisemap.forEach((value: any, key: any) => {
      value.forEach((l: any) => {
        if (l.id != selectedSection.id) {
          l.isSelected = false;
        }
      })
    });
    this.selectedMeeting = selectedSection;
    this.loadMeetingDetail(selectedSection);
    this.loadMeetingContacts(selectedSection);
    this.loadMoglixAttendees(selectedSection);
    this.fetchMOMDetails(selectedSection);
    this.fetchDraftMOMDetails(selectedSection);
    this.fetchTaskDetails(selectedSection);
  }
  loadMeetingDetail(selectedSection) {
    console.log("loadMeetingDetail selected meeting ", selectedSection)
    this.meetingDetail = {};
    this.meetingDetail.subject = selectedSection.subject;
    this.meetingDetail.starttime = selectedSection.start.time;
    this.meetingDetail.endtime = selectedSection.end.time;
    this.meetingDetail.body = selectedSection.body.content;
    if(new Date().getTime()>this.meetingDetail.endtime){
      this.enableAddMomButton=true;
    }else{
      this.enableAddMomButton=false;
    }
    this.momAttendees = [];
    this.momAttendees = selectedSection.moglixAttendees;
    this.loadUsers(this.sobBusinessId);
    this.attendeeLabel = this.vedantaLabel;
  }
  loadUsers(businessId: string) {
    const obj = {
      from: 0,
      size: 100,
      businessId: businessId
    };

    this.settingService.getUserDetailsForOwnerDropDown(obj).subscribe(list => {
      const userList = list['result'].map(user => {
        const teamList = user.userTeams ? user.userTeams.map(team => team.id) : [];
        return { ...user, userTeams: teamList };
      });
      userList.sort((a, b) => a.name.localeCompare(b.name));
      if (businessId === Constants.businessIdForCRM) {
        const filteredUserList = userList.filter(user => user.id !== this.huntingUserId);
        this.allUserList = filteredUserList;
      }
      if (businessId === Constants.businessIdForSOB) {
        const filteredUserList = userList.filter(user => user.id !== this.sobUserId);
        this.allUserList = filteredUserList;
      }
      if (businessId === Constants.businessIdForSRM) {
        const filteredUserList = userList.filter(user => user.id !== this.srmUserId);
        this.allUserList = filteredUserList;
      }
      this.allUserListSearch = this.allUserList;
      this.allUserListSearchNewMeeting=this.allUserList;
      this.allTaskOwnerUserListSearch = this.allUserList;
      this.allTaskAssigneeUserListSearch = this.allUserList;
      this.allTaskCollaboratorUserListSearch = this.allUserList;
    });
  }
  loadMeetingContacts(selectedSection) {
    this.meetingContacts = [];
    let attendees = selectedSection.attendees;
    let organizer = selectedSection.organizer.emailAddress.address;
    attendees.forEach((c: any) => {
      let contact: any = {};
      contact.name = c.emailAddress.name;
      contact.email = c.emailAddress.address;
      if (organizer === c.emailAddress.address) { contact.organiser = true; }
      this.meetingContacts.push(contact);
    });
  }
  loadMoglixAttendees(selectedSection) {
    this.momAttendees = [];
    let moglixAttendees = selectedSection.collaboratorDetails;
    if (moglixAttendees) {
      moglixAttendees.forEach((c: any) => {
        let contact: any = {};
        contact.name = c.emailAddress.name;
        contact.email = c.emailAddress.address;
        this.momAttendees.push(contact);
      });
    }
  }
  fetchTaskDetails(selectedMeeting: any): void {
    this._kService.getTaskByMeetingId(selectedMeeting.id).subscribe({
      next: (res) => {
        console.log("fetchTaskDetails Task Details", res);
        let response;
        if(res && res['result']){
          response = res['result'];
        }
        if (response) {
          this.updateTaskDetails(response);
        } else {
          this.taskDetails = {
            addMomDisplayText: "Add MOM & Task"
          };
        }
      },
      error: (err) => {
        console.error("Error fetching task details:", err);
  
        // Handle API errors here
        this.taskDetails = {
          addMomDisplayText: "Add MOM & Task"
        };
      }
    });
  
    // Log taskDetails after the async response, just for debugging purposes
    console.log("taskDetail", this.taskDetails);
  }
  fetchMOMDetails(selectedMeeting: any): void {
    this._kService.fetchMOM(selectedMeeting.id).subscribe(res => {
      console.log(" fetchMOMDetails MOM Details", res);
      const response = res['result'];
      if (response) {
        this.updateMOMDetails(response, selectedMeeting.selectedPlatform);
      } else {
        this.momDetails = {
          addMomDisplayText: "ADD MOM & TASK"
        };
      }
    });
    console.log("meetingDetail", this.meetingDetail);
  }
  fetchDraftMOMDetails(selectedMeeting: any): void {
    this._kService.fetchDraftMOM(selectedMeeting.id).subscribe(res => {
      console.log("fetchDraftMOM MOM Details", res);
      const response = res['result'];
      if (response) {
        this.updateDraftMOMDetails(response);
      }else{
        this.draftMomDetails={
          id:null,
          momType:null,
          fetchedMOMContent: "",
          fetchedMOMCreationDate: "",
          fetchedMOMNextActionDate: "",
          fetchedMOMValue: "",
          fetchedMOMAttachment: [],
          fetchedAttendees:[]
        };
        if(this.draftMomDetails){
          this.isHidden=true;
          let attendeeIds=this.draftMomDetails.fetchedAttendees;
          let fetchedUserList = this.allUserList.filter(option => attendeeIds.includes(option.id));
          this.selectedAttendees=fetchedUserList;
          this.content=this.draftMomDetails.fetchedMOMContent;
          this.nextActionDate=this.draftMomDetails.fetchedMOMNextActionDate;
        }
      }
    });
    console.log("fetchDraftMOM", this.meetingDetail);
  }
  private updateTaskDetails(response: any): void {
    this.taskDetails.id=response.id;
    this.taskDetails.addMomDisplayText = "UPDATE MOM & TASK";
    this.taskDetails.fetchedTaskTitle = response.title;
    this.taskDetails.fetchedTaskDescription = response.description;
    this.taskDetails.fetchedTaskCreationDate = response.createdAt;
    this.taskDetails.fetchedTaskDueDate = this._kService.getFormattedDate(response.dueDate);
    
    if (response.plants && Array.isArray(response.plants)) {
      this.taskDetails.fetchedTaskPlantsValue = response.plants.map((plant: any) => plant.name).join(', ');
    }
    if (response.accounts && Array.isArray(response.accounts)) {
      this.taskDetails.fetchedTaskAccountsValue = response.accounts.map((account: any) => account.name).join(', ');
    }
    if (response.owners && Array.isArray(response.owners)) {
      this.taskDetails.fetchedTaskOwnerValue = response.owners.map((owner: any) => owner.name).join(', ');
    }
    if (response.assignees && Array.isArray(response.assignees)) {
      this.taskDetails.fetchedTaskAssigneeValue = response.assignees.map((owner: any) => owner.name).join(', ');
    }
    if (response.collaborators && Array.isArray(response.collaborators)) {
      this.taskDetails.fetchedTaskCollaboratorValue = response.collaborators.map((owner: any) => owner.name).join(', ');
    }
    console.log("updateTaskDetails task details", this.taskDetails)
  }
  private updateMOMDetails(response: any, selectedPlatform: any): void {
    this.momDetails.momType=response.momType;
    this.momDetails.addMomDisplayText = "UPDATE MOM & TASK";
    this.momDetails.fetchedMOMContent = response.message;
    this.momDetails.fetchedMOMCreationDate = response.createdAt;
    this.momDetails.fetchedMOMNextActionDate = response.nextActionDate;
    this.momDetails.fetchedMOMValue = response.valueDelivery ? "YES" : "NO";
    this.momDetails.fetchedMOMAttachment = response.attachments;
    if (response.plants && Array.isArray(response.plants)) {
      this.momDetails.fetchedMOMPlantsString = response.plants.map((plant: any) => plant.name).join(', ');
    }
    if (response.accounts && Array.isArray(response.accounts)) {
      this.momDetails.fetchedMOMAccountsString = response.accounts.map((account: any) => account.name).join(', ');
    }
    console.log("momDetails", this.momDetails)
  }
  private updateDraftMOMDetails(response: any): void {
    this.draftMomDetails.momType=response.momType;
    this.draftMomDetails.id=response.id;
    this.draftMomDetails.fetchedMOMContent = response.message;
    this.draftMomDetails.fetchedMOMCreationDate = response.createdAt;
    this.draftMomDetails.fetchedMOMNextActionDate = response.nextActionDate;
    this.draftMomDetails.fetchedMOMValue = response.valueDelivery ? "YES" : "NO";
    this.draftMomDetails.fetchedMOMAttachment = response.attachments;
    this.draftMomDetails.fetchedAttendees=response.attendees;
    if(this.draftMomDetails){
      this.momDetails.addMomDisplayText = "Resume Draft MOM";
      this.isHidden=false;
      let attendees=this.draftMomDetails.fetchedAttendees;
      let attendeeIds=[];
      if(attendees && attendees.length>0){
          attendeeIds=attendees.map(attendee=>attendee.id)
      }
      let fetchedUserList = this.allUserList.filter(option => attendeeIds.includes(option.id));
      this.selectedAttendees=fetchedUserList;
      this.content=this.draftMomDetails.fetchedMOMContent;
      this.nextActionDate=this.draftMomDetails.fetchedMOMNextActionDate;
      this.mentionList=response.mention;
    }
    console.log("draftmomDetails", this.draftMomDetails)
  }
  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const selectedDate = this.date.value.clone(); // Clone to avoid mutating the original value
    selectedDate.month(normalizedMonth.month());

    // Set the start date to the beginning of the month as a Unix timestamp
    const startDate = selectedDate.clone().startOf('month').valueOf();
    this.startDateSelected = startDate;

    // Set the end date to the end of the month as a Unix timestamp
    const endDate = selectedDate.clone().endOf('month').valueOf();
    this.endDateSelected = endDate;
    const obj = {
      "startDate": this.startDateSelected,
      "endDate": this.endDateSelected
    };

    this.date.setValue(selectedDate); // Set the updated date back to the form control
    datepicker.close();
    if (this.selectedTab === 'MOM') {
      this.loadMOMMeetings(obj);
    } else {
      this.loadMeetings(obj);
    }
  }
  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }
  loadMOMMeetings(obj) {
    this.isLoading = true;
    this.settingService.fetchMOMMeetings(obj).subscribe(response => {
      this.isLoading = false;
      this.datewisemap = new Map(Object.entries(response["result"]));
      this.meetingDetail = this.datewisemap.size > 0 ? this.datewisemap : null;
      this.meetingContacts = this.datewisemap.size > 0 ? this.datewisemap : [];
      this.isRefresh = true;
      const reversedEntries = Array.from(this.datewisemap.entries()).reverse();

      let isNavigationClicked = false;
      reversedEntries.forEach(([key, value]: [any, any]) => {
        if (!isNavigationClicked) {
          const keyDate = new Date(key);
          if (keyDate.getDate() > this.currentTime.getDate() - 1) {
            for (let index = 0; index < value.length; index++) {
              const element = value[index];
              const meetingStartTime = new Date(element.start.dateTime);
              meetingStartTime.setHours(meetingStartTime.getHours() + 5); // Subtract 5 hours
              meetingStartTime.setMinutes(meetingStartTime.getMinutes() + 30); // Subtract 30 minutes
              if (meetingStartTime > this.currentTime) {
                this.onClickNavigation(value[index]);
                isNavigationClicked = true;
                break;
              }
              else if (keyDate.getDate() > this.currentTime.getDate()) {
                this.onClickNavigation(value[index]);
                isNavigationClicked = true;
                break;
              }
            }
          }
        }
      });

    });
  }
  newMeetingDialog() {
    const dialogRef = this.dialog.open(NewMeetingComponent, {
      width: '50%',
      height: '100%',
      position: { bottom: '0px', right: '0px' },

    });
    dialogRef.afterClosed().subscribe((result) => {
      if (this.selectedTab === 'MOM') {
        this.loadMOMMeetings({});
      } else {
        this.loadMeetings({});
      }
    });
  }
  refreshMeetingFromAzure() {
    const selectedDate = this.date.value.clone(); // Clone to avoid mutating the original value
    const startDate = selectedDate.clone().startOf('month').valueOf();
    this.startDateSelected = startDate;
    const endDate = selectedDate.clone().endOf('month').valueOf();
    this.endDateSelected = endDate;

    const obj = {
      "startDate": this.startDateSelected,
      "endDate": this.endDateSelected,
      "pullFromAzure": true
    };

    this.date.setValue(selectedDate); // Set the updated date back to the form control
    if (this.selectedTab === 'MOM') {
      this.loadMOMMeetings(obj);
    } else {
      this.loadMeetings(obj);
    }
    // this.loadPlants();
    this.isRefresh = true;
  }
  cancelMOM() {
    this.file = null;
    this.content = ''
    this.nextActionDate = null;
    this.toggleVisibility();
  }
  downloadSingleDocument(id) {
    this._kService.downloadSingleUploadDocument(id).subscribe(res => {
      this.downloadSheetCommonMethod(res);
    })
  }
  downloadSheetCommonMethod(res) {
    var contentDispositionHeader = res.headers.get('content-disposition');
    let resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1];
    let fileName = resultDownload.replace(/"/g, '')
    saveAs(res.body, fileName);
    return fileName;
  }
  cancelTask() {
    this.taskTitle = null;
    this.taskDescription = ''
    this.taskDueDate = null;
    this.selectedTaskAssignees=[];
    this.selectedTaskOwners=[];
    this.selectedTaskCollaborators=[];
    this.toggleVisibility();
  }
  toggleVisibility() {
    this.attendeeLabel = this.vedantaLabel;
    this.isHidden = !this.isHidden;
  }
  postTask() {
    let errorMessage = "";
    let tmpTaskDescription = this.taskDescription;
    if (this.taskDescription) {
      tmpTaskDescription = tmpTaskDescription.replace(/(<([^>]+)>)/ig, '');
    }
    let tmpTaskTitle = this.taskTitle;
    if (this.taskTitle) {
      tmpTaskTitle = tmpTaskTitle.replace(/(<([^>]+)>)/ig, '');
    }
    if (!this.taskTitle || !this.taskTitle.trim()) {
      errorMessage += "Task Title";
    }
    if (!this.taskDescription || !this.taskDescription.trim()) {
      if (errorMessage) {
        errorMessage += ", ";
      }
      errorMessage += "Task Description";
    }
    if (!this.taskDueDate) {
      if (errorMessage) {
        errorMessage += ", ";
      }
      errorMessage += "Due Date";
    }
    console.log('selectedTaskOwners', this.selectedTaskOwners)
    if(!this.selectedTaskOwners || this.selectedTaskOwners.length==0){
      if (errorMessage) {
        errorMessage += ", ";
      }
      errorMessage += "Task Owner";
    }
    if(!this.selectedTaskAssignees  || this.selectedTaskAssignees.length==0){
      if (errorMessage) {
        errorMessage += ", ";
      }
      errorMessage += "Task Assignee";
    }    
    if (errorMessage) {
      const lastCommaIndex = errorMessage.lastIndexOf(", ");
      if (lastCommaIndex !== -1) {
        errorMessage = errorMessage.substring(0, lastCommaIndex) + " and" + errorMessage.substring(lastCommaIndex + 1);
      }
    }
    if (!errorMessage || !errorMessage.trim) {
      this.addTask(this._kService);
    } else {
      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 3500,
        data: errorMessage + " are mandatory"
      });
    }
  }
  private addTask(service:any) {
    let selectedTaskOwnerValue;
    let selectedTaskAssigneValue;
    let selectedTaskCollaboratorValue;
    if (this.selectedTaskOwners) {
      selectedTaskOwnerValue = this.selectedTaskOwners.map(attendee => ({"id":attendee.id}));
    }
    if (this.selectedTaskAssignees) {
      selectedTaskAssigneValue = this.selectedTaskAssignees.map(attendee => ({"id":attendee.id}));
    }
    if (this.selectedTaskCollaborators) {
      selectedTaskCollaboratorValue = this.selectedTaskCollaborators.map(attendee => ({"id":attendee.id}));
    }
    let taskDueDate:Date;
    if (!(this.taskDueDate instanceof Date)) {
      taskDueDate = new Date(this.taskDueDate); // Convert to Date if it's not already
    }
    let obj = {
      "meeting": {"id":this.selectedMeeting.id},
      "title": this.taskTitle,
      "description": this.taskDescription,
      "dueDate": taskDueDate.getTime(),
      "owners":selectedTaskOwnerValue,
      "assignees":selectedTaskAssigneValue,
      "collaborators":selectedTaskCollaboratorValue
    }
    let reqFunc = null;
    reqFunc = service.addTask(obj);

    reqFunc.subscribe(res => {
      this.sendMomMail = false;
      if (res['status'] == 200) {
        this._snackBar.openFromComponent(SuccessToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'success'],
          duration: 3500,
          data: "Task added successfully"
        });
        this.isHidden = true;
        this.cancelTask();
        console.log("addTask selected Meeting", this.selectedMeeting);
        this.fetchTaskDetails(this.selectedMeeting);
      }
    });
  }
  postMOM(momtype:string,source:string) {
    let tmp = this.content;
    if (this.content) {
      tmp = tmp.replace(/(<([^>]+)>)/ig, '');
    }
    let errorMessage = "";
    if (!this.content || !this.content.trim()) {
      errorMessage += "Comment";
    }
    if (errorMessage) {
      const lastCommaIndex = errorMessage.lastIndexOf(", ");
      if (lastCommaIndex !== -1) {
        errorMessage = errorMessage.substring(0, lastCommaIndex) + " and" + errorMessage.substring(lastCommaIndex + 1);
      }
    }
    if (!errorMessage || !errorMessage.trim) {
      this.addMOM(this._kService,momtype,source);
    } else {
      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 3500,
        data: errorMessage + " are mandatory"
      });
    }
  }
  private addMOM(service: any,momtype:any,source:string) {
    let attendees;
    if (this.selectedAttendees) {
      attendees = this.selectedAttendees.map(attendee => ({"id":attendee.id}));
    }
    let obj = {
      "attendees": attendees,
      "meeting": {"id":this.selectedMeeting.id},
      "message": this.content,
      "nextActionDate": this.nextActionDate,
      'source': source,
      "sendMomMail": this.sendMomMail,
      "momType":momtype,
      "id":this.draftMomDetails.id,
      "mention":this.mentionList
    }
    let reqFunc = null;
    reqFunc = service.addMOM(obj);

    reqFunc.subscribe(res => {
      this.sendMomMail = false;
      if (res['status'] == 200) {
        this._snackBar.openFromComponent(SuccessToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'success'],
          duration: 3500,
          data: "MOM added successfully"
        });
        this.isHidden = true;
        if (this.file) {
          const uploadData = new FormData();
          uploadData.append('file', this.file, this.file.name);
          service.uploadSubDocuments(uploadData, res['result'].id, ("momId")).subscribe(file => {
            if (file['status'] == 200) {
              this._snackBar.openFromComponent(SuccessToastComponent, {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                panelClass: ['atom-toast', 'success'],
                duration: 3500,
                data: "Attachment uploaded successfully"
              });
            }
            else {
              this._snackBar.openFromComponent(ErrorToastComponent, {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                panelClass: ['atom-toast', 'error'],
                duration: 3500,
                data: "Error while uploading attachment. Please try again." + file
              });
            }
            console.log("Attachment saved for MOM", res);
          });
        }
        this.clearForm();
        this.selectedMeeting['momType']=momtype;
        console.log("addMOM selected meeting", this.selectedMeeting);
        this.fetchMOMDetails(this.selectedMeeting);
        this.fetchDraftMOMDetails(this.selectedMeeting);
      }
    });
  }
  fileUpload(event) {
    if (event[0]) {
      this.file = event[0];
    }
    else {
      this.file = event.target.files[0];
    }
  }
  rmeoveSelectedFile() {
    this.file = null;
  }
  clearForm() {
    this.file = null;
    this.content = '';
    this.nextActionDate = null;
    this.mentionList=[];
  }
  sendMail(event) {
    this.sendMomMail = event;
  }
  updateMeetingEntitiesForSob(plantIdList: string[] = [], accountIds: any[] = []) {
    this.selectedMeeting.plantIdList = plantIdList;
    this.selectedMeeting.accountIds = accountIds;
    const obj = {
      id: this.selectedMeeting.id,
      plantIdList: plantIdList,
      accountList: accountIds,
      selectedPlatform: 'SOB'
    };
    this.settingService.associateEntitiesToMeetings(obj).subscribe((res) => {
      const successMessage = accountIds != null && accountIds.length > 0 ? "Plants Updated Successfully" : "Accounts Updated Successfully";
      const errorMessage = accountIds != null && accountIds.length > 0 ? "Error while updating Plants" : "Error while updating Accounts";
      if (res['status'] === 200) {
        this.datewisemap.forEach((value: any, key: any) => {
          value.forEach((l: any) => {
            if (l.id == res['result'].id) {
              l.selectedPlatform = res['result'].selectedPlatform;
            }
          })
        });

        this._snackBar.openFromComponent(SuccessToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'success'],
          duration: 3500,
          data: successMessage
        });
      } else {
        this._snackBar.openFromComponent(ErrorToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'error'],
          duration: 3500,
          data: errorMessage + res['status']
        });
      }
    });
  }
  onAttendeeSelectionChange(selection: any) {
    this.selectedAttendees = selection;
    console.log('selectedAttendees',this.selectedAttendees)
  }
  onTaskOwnerSelectionChange(selection: any) {
    this.selectedTaskOwners = selection;
    console.log('selectedTaskOwners',this.selectedTaskOwners)
  }
  onTaskAssigneeSelectionChange(selection: any) {
    this.selectedTaskAssignees = selection;
    console.log('selectedTaskAssignees',this.selectedTaskAssignees)
  }
  onTaskCollaboratorsSelectionChange(selection: any) {
    this.selectedTaskCollaborators = selection;
    console.log('selectedTaskCollaborators',this.selectedTaskCollaborators)
  }
  filterOptionsAttendees(event) {
    this.allUserListSearch = this.allUserList.filter(option => (option.name).toLowerCase().includes(event.toLowerCase()));
  }
  filterOptionsTaskOwner(event) {
    this.allTaskOwnerUserListSearch = this.allUserList.filter(option => (option.name).toLowerCase().includes(event.toLowerCase()));
  }
  filterOptionsTaskAsshignee(event) {
    this.allTaskAssigneeUserListSearch = this.allUserList.filter(option => (option.name).toLowerCase().includes(event.toLowerCase()));
  }
  filterOptionsTaskCollaborator(event) {
    this.allTaskCollaboratorUserListSearch = this.allUserList.filter(option => (option.name).toLowerCase().includes(event.toLowerCase()));
  }
  async suggestPeople(searchTerm) {
    return new Promise((resolve, reject) => {
      let allPeople = [];
      let obj = {
        searchKey: searchTerm,
        businessId: localStorage.getItem('selectedBusinessId')
      };
      this.settingService.fetchUserListAutosuggest(obj).subscribe(
        (response) => {
          if (response) {
            let alluser: any = response;
            for (let i = 0; i < alluser.length; i++) {
              let user = { id: alluser[i]["id"], value: alluser[i]["name"] };
              allPeople.push(user);
            }
            console.log('allPeople', allPeople);
            resolve(allPeople);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  toggleMeetingDetailsPopup(): void {
    this.showMeetingDetailsPopup = !this.showMeetingDetailsPopup;
  }
  toggleAdHocMeetingPopup(): void {
    this.showAdHocMeetingPopup = !this.showAdHocMeetingPopup;
    if (this.showAdHocMeetingPopup) {
      this.clearNewMeetingForm();
    }
  }
  clearNewMeetingForm(){
      this.contentForNewMeeting='';
      this.newMeetingSelectedAttendees=[];
      this.mentionListForNewMeeting=[];
      this.selectedAccounts=[];
      this.selectedPlantsForMeeting=[];
      this.selectedContact=[];
      this.plantOptionsSelected=[];
      this.contactList = [];
      this.contentForNewMeeting = '';
      this.meetingTitle = '';
      this.selectedMeetingType = '';
      this.selectedAttendees = [];
      this.nextActionDateForNewMeeting = new Date();
      this.startDateValue = null;
      this.startTimeValue = null;
      this.endDateValue = null;
      this.endTimeValue = null;
      this.selectedMeetingCategory='';
      this.selectedProduct=[];
      this.selectedVariant=[];
      this.remarks='';
      this.loadUsers(this.sobBusinessId);
      this.loadAccounts();
  }
  selectDate(date: string) {
    this.endDateValue = null;
    this.endTimeValue = null;
    this.startTimeValue = null;

    this.startDateValue = date;
    let sDate = new Date(this.startDateValue);
    if (sDate > this.minEndDate) {
      this.minEndDate = sDate;
    }
    else {
      this.minEndDate = new Date();
      this.minEndDate.setDate(this.minEndDate.getDate() - 2);
    }
  }
  selectTime(selectedTime: string) {
    this.startTimeValue = selectedTime;
    const [hours, minutes] = this.startTimeValue.split(":").map(Number);

    this.finalStartDateTime = new Date(this.startDateValue);
    this.finalStartDateTime.setHours(hours, minutes, 0, 0);
    this.timesEnd = this.times; // for displaying Available End Times

    const selectedIndex = this.times.findIndex(time => time === selectedTime);
    let startDate = new Date(this.startDateValue);

    if (selectedIndex !== this.times.length - 1) {
      this.endDateValue = this.startDateValue;
      this.timesEnd = this.timesEnd.slice(selectedIndex + 1);
      this.minEndDate = startDate;
    }
    else {
      if (startDate > this.minEndDate) {
        startDate.setDate(startDate.getDate() + 1);
        this.minEndDate = startDate;
      }

      this.timesEnd = this.times; // for displaying Available End Times
      const endDateValue = new Date(this.startDateValue);
      endDateValue.setDate(endDateValue.getDate() + 1);

      this.endDateValue = endDateValue.toISOString(); // Convert back to string
      this.endTimeValue = null;
    }
  }
  selectEndDate(date: string) {
    this.endTimeValue = null;
    this.endDateValue = date;
    const endDateValue = new Date(this.endDateValue);
    const startDateTimeValue = new Date(this.finalStartDateTime);
    if (startDateTimeValue < endDateValue) {
      this.timesEnd = this.times;
    }
  }
  selectEndTime(selectedTime: string) {

    this.endTimeValue = selectedTime;

    const [hours, minutes] = this.endTimeValue.split(":").map(Number);
    const endDate = new Date(this.endDateValue);
    this.finalEndDateTime = endDate;
    this.finalEndDateTime.setHours(hours, minutes, 0, 0);

    const currentTime: Date = new Date();
    if (currentTime < this.finalStartDateTime) {
      this.isCreatingPreviousMeeting = false;
    } else if (currentTime > this.finalStartDateTime) {
      this.isCreatingPreviousMeeting = true;
    } else {
      this.isCreatingPreviousMeeting = true;
    }
  }
  combineDateAndTime(startDateValue: any, timeToAdd: any) {
    const startDate = new Date(startDateValue);
    const [hours, minutes, meridiem] = timeToAdd.match(/(\d+):(\d+) (\w{2})/).slice(1);

    let parsedHours = parseInt(hours, 10);
    if (meridiem.toLowerCase() === 'pm' && parsedHours !== 12) {
      parsedHours += 12;
    } else if (meridiem.toLowerCase() === 'am' && parsedHours === 12) {
      parsedHours = 0;
    }
    startDate.setHours(parsedHours, parseInt(minutes, 10), 0, 0);

    return startDate;
  }
  toggleAddMOMPopup() {
    this.showAddMOMPopup = !this.showAddMOMPopup;
  }
  toggleAddTASKPopup() {
    this.showAddTASKPopup = !this.showAddTASKPopup;
  }
  loadAccounts() {
    this.getLaneTaskAccountList();
  }
  filterOptionsAccounts(event) {
    const filterValue = event.target.value.toLowerCase();
    this.accountOptionsSelected = this.accounts.filter(option => (option.name).toLowerCase().includes(filterValue));
  }
  filterOptionsPlants(event) {
    const filterValue = event.target.value.toLowerCase();
    this.plantOptionsSelected = this.plants.filter(option => (option.name).toLowerCase().includes(filterValue));
    this.plantOptionsSelected.slice(0, 100);
  }
  handleApplySelections() {
    console.log("handleApplySelections", this.selectedAccounts);
    this.customSelectWithMultipleAccounts = this.selectedAccounts.map(item => item.id);
    this.loadPlants();
    this.fetchContacts();
  }
  loadPlants() {
      this.isLoading = true;
      const query = this.getPlantsQuery(this.sobPlantBoardId, this.customSelectWithMultipleAccounts);
      this._kService.getLaneTask(query).subscribe(response => {
        this.isLoading = false;
        this.plants = response['result'];
        this.plantOptions = response['result'];
        this.plantOptions.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
        this.plantOptionsSelected = this.plantOptions;
        this.plantOptionsSelected.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
        this.plantOptionsAllIds = this.plantOptionsSelected.map(element => element.id);
        this.plantOptionsSelected = this.plantOptionsSelected.slice(0, 100);
      });
  }
  async fetchContacts() {
    const query = this.getContactsQuery(this.sobContactBoardId, this.customSelectWithMultipleAccounts);
    this.isLoading = true;
    this._kService.getLaneTask(query).subscribe(task => {
      this.isLoading = false;
      if (task['result'] != null && task['result'].length > 0) {
        this.contactList = task['result'];
      }
      else {
        setTimeout(() => {
          this._snackBar.openFromComponent(InfoToastComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['atom-toast', 'information'],
            duration: 3500,
            data: "No Contacts Mapped With Selected Account."
          });
        }, 1500);
      }
    });
  }
  getLaneTaskAccountList() {
    this.isLoading = true;
    this.settingService.fetchAccountsByUserId(this.huntingUserId).subscribe(res => {
      this.isLoading = false;
      this.accounts = res['result'];
      this.accountOptionsSelected = this.accounts;
      this.accountOptionsSelected.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
      this.accountOptionsSelected = this.accountOptionsSelected.slice(0, 100);
    },
      (error) => {
        console.log('Error while getting accounts', error);
      }
    );
  }
  getContactsQuery(boardId: any, accountIds: any) {
    const accountQueries = accountIds.map(value => {
      return {
        field: 'data.account.raw',
        value: value
      };
    });

    return {
      where: {
        boardId: {
          type: 'search',
          operator: 'or',
          value: [
            {
              field: 'boardId.raw',
              value: boardId,
            },
          ],
        },
      },
      nestedWhere: {
        account_query: {
          type: 'search',
          operator: 'or',
          value: accountQueries
        }
      },
      sort: {
        field: 'updatedAt',
        order: 'desc',
      },
      from: 0,
      size: 20,
    };
  }
  getPlantsQuery(boardId: any, accountIds: any) {
    const accountQueries = accountIds.map(value => {
      return {
        field: 'data.account.raw',
        value: value
      };
    });

    return {
      where: {
        boardId: {
          type: 'search',
          operator: 'or',
          value: [
            {
              field: 'boardId.raw',
              value: boardId,
            },
          ],
        },
      },
      nestedWhere: {
        account_query: {
          type: 'search',
          operator: 'or',
          value: accountQueries
        }
      },
      sort: {
        field: 'updatedAt',
        order: 'desc',
      },
      from: 0,
      size: 20,
    };
  }
  clearAccounts() {
    this.searchControlAccounts = '';
    this.accountOptionsSelected = this.accounts.map(a => ({ ...a }));
    this.selectedPlantsForMeeting=[];
    this.selectedContact=[];
    this.plantOptionsSelected=[];
    this.contactList=[];
  }
  clearPlants() {
    this.searchControlPlants = '';
    this.plantOptions = this.plants.map(a => ({ ...a }));
    this.selectedPlantsForMeeting=[];
  }
  clearContacts() {
    console.log('clearContacts');
    this.selectedContact = null;
  }
  onApplyAccounts() {
    this.searchControlAccounts = "";
    this.handleApplySelections();
  }
  onApplyContacts() {
    console.log('on apply Contacts selected for adhoc meeting', this.selectedContact);
  }
  onApplyPlants() {
    this.searchControlPlants = "";
    console.log('on apply plant selected for meeting', this.selectedPlantsForMeeting);
  }
  onNewMeetingAttendeeSelectionChange(selection: any) {
    this.newMeetingSelectedAttendees = selection;
  }
  newMeetingfilterOptionsAttendees(event) {
    this.allUserListSearchNewMeeting = this.allUserList.filter(option => (option.name).toLowerCase().includes(event.toLowerCase()));
  }
  validateRequest() {
    let errorMessage = null;
    if (this.meetingTitle == null || this.meetingTitle.trim() == '') {
      errorMessage = 'Meeting Title :  Required';
      return {
        "message": errorMessage,
        "isValid": false
      };
    }
    if (this.customSelectWithMultipleAccounts == null || this.customSelectWithMultipleAccounts.length < 1) {
      errorMessage = 'Account :  Required';
      return {
        "message": errorMessage,
        "isValid": false
      };
    }
    if (this.selectedMeetingType == null || this.selectedMeetingType.trim() === '') {
      errorMessage = 'Meeting Type :  Required';
      return {
        "message": errorMessage,
        "isValid": false
      };
    }
    if (this.selectedMeetingCategory == null || this.selectedMeetingCategory.trim() === '') {
      errorMessage = 'Meeting Category :  Required';
      return {
        "message": errorMessage,
        "isValid": false
      };
    }
    else if(this.selectedMeetingCategory.includes('New Product Development')){
      if (this.selectedProduct == null || this.selectedProduct.length === 0) {
        errorMessage = 'Product :  Required';
        return {
          "message": errorMessage,
          "isValid": false
        };
      }
      if (this.selectedVariant == null || this.selectedVariant.length === 0) {
        errorMessage = 'Varient :  Required';
        return {
          "message": errorMessage,
          "isValid": false
        };
      }
    }
    if (this.finalStartDateTime == null || this.finalStartDateTime == undefined) {
      errorMessage = 'Start Datetime :  Required';
      return {
        "message": errorMessage,
        "isValid": false
      };
    }
    if (this.finalEndDateTime == null || this.finalEndDateTime == undefined) {
      errorMessage = 'End Datetime :  Required';
      return {
        "message": errorMessage,
        "isValid": false
      };
    }
    if (this.contentForNewMeeting == null || this.contentForNewMeeting.trim() == '') {
      errorMessage = 'Meeting body : Required';
      return {
        "message": errorMessage,
        "isValid": false
      };
    }
    else {
      return {
        "message": "Success",
        "isValid": true
      };
    }

  }
  postNewMeeting() {
    let isValidResp = this.validateRequest();
    if (!isValidResp || !isValidResp['isValid']) {
      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 3500,
        data: isValidResp.message
      });
      return;
    }
    const originalStartTimeZone = this.getTimeZone(this.finalStartDateTime);
    const originalEndTimeZone = this.getTimeZone(this.finalEndDateTime);

    let tmp = this.contentForNewMeeting;
    if (this.contentForNewMeeting) {
      tmp = tmp.replace(/(<([^>]+)>)/ig, '');
    }
    if (this.contentForNewMeeting && (this.contentForNewMeeting.trim() != "" && tmp.trim() != "")) {
      const body = {
        contentType: 'html',
        content: this.contentForNewMeeting,
      };
      const start = {
        dateTime: this.finalStartDateTime,
        timeZone: originalStartTimeZone
      };
      const end = {
        dateTime: this.finalEndDateTime,
        timeZone: originalEndTimeZone
      };
      let attendeesList;
        attendeesList = this.selectedContact.map(contact => {
          const searchData = contact.searchData;
          return {
            type: 'required',
            emailAddress: {
              name: searchData.name,
              address: searchData.email,
            },
          };
        });
      let attendees;
      if (this.newMeetingSelectedAttendees) {
        attendees = this.newMeetingSelectedAttendees.map(attendee => attendee.id);
      }
      let obj = {
        'originalStartTimeZone': originalStartTimeZone,
        'originalEndTimeZone': originalEndTimeZone,
        'subject': this.meetingTitle,
        "collaborators": attendees,
        'body': body,
        'start': start,
        'end': end,
        'attendees': attendeesList,
        'meetingType': this.selectedMeetingType,
        'selectedPlatform': this.selectedPlatform,
        'source': 'MOBILE',
        'product': this.selectedProduct,
        'variant': this.selectedVariant,
        'remarks': this.remarks,
        'meetingCategory': this.selectedMeetingCategory,
        'accountList': this.customSelectWithMultipleAccounts,
        'plantIdList': this.selectedPlantsForMeeting.map(element => element.id)
      };
      this.settingService.createTeamMeetingByUserId(obj, this.sobUserId).subscribe((res) => {
        if (res) {
          const status = res['status'];
          if (status === 200) {
            this._snackBar.openFromComponent(SuccessToastComponent, {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              panelClass: ['atom-toast', 'success'],
              duration: 3500,
              data: "New Meeting Created Successfully"
            });
            this.clearNewMeetingForm();
            console.log("New Meeting Created Successfully ");
          }
          else {
            this._snackBar.openFromComponent(ErrorToastComponent, {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              panelClass: ['atom-toast', 'error'],
              duration: 3500,
              data: "Failed to create new meeting"
            });
          }
        }
      });
    }
  }
  getTimeZone(date: Date) {
    const dateToTimeZone = new Date(date);
    const timeZone = new Intl.DateTimeFormat("en", { timeZoneName: "long" }).formatToParts(dateToTimeZone).pop().value;
    return timeZone;
  } 
}